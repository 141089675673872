:root {
  --layout-header-footer-background-color: #f0f2f5;
  --layout-header-height: 10vh;
  --layout-footer-height: 8vh;
  --page-height: 100vh;
  --page-width: 100vw;
  --layout-sider-width: 250px;
  --layout-sider-background-color: rgb(36, 39, 48);
  --layout-content-height: 80vh;
  --layout-content-width: 100%;
  --layout-content-background-color: #f0f2f5;
  --layout-menu-selected-background-color: rgb(41, 50, 60);
  --default-grey: rgb(106, 116, 133);
  /* --trigger-svg-color: #000; */
}

::-webkit-scrollbar {
  width: 4px !important;
  height: 4px !important;
  border-radius: 50px !important;
}

::-webkit-scrollbar-thumb {
  background-color: lightgrey !important;
  border-radius: 50px !important;
}

.login-container {
  background-image: url("https://gw.alipayobjects.com/zos/rmsportal/TVYTbAXWheQpRcWDaDMu.svg");
  background-repeat: no-repeat;
  background-position: center 110px;
  background-size: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
.login-overlay {
  background: #f0f2f5;
  height: 100%;
  width: 100%;
  position: absolute;
  z-index: -1;
}
.login-main {
  position: absolute;
  top: 0;
  margin-top: 5%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.login-card,
.signup-card,
.forgot-password-card {
  width: 450px;
  box-shadow: rgba(17, 12, 46, 0.15) 0px 48px 100px 0px;
}

.sider {
  height: var(--page-height);
  width: var(--layout-sider-width);
  background: var(--layout-sider-background-color) !important;
}

.sider .ant-menu,
.footer .ant-menu {
  background: var(--layout-sider-background-color) !important;
}

.sider .ant-layout-sider-trigger {
  background: var(--layout-menu-selected-background-color) !important;
}

.sider .ant-layout-sider-trigger svg {
  margin-top: 18px;
}

.sider .ant-menu {
  height: 78.5vh !important;
  position: relative !important;
}

.sider .user-control {
  position: absolute !important;
  bottom: 0px !important;
  width: 100%;
  padding-left: 28px !important;
  color: var(--default-grey);
}

.sider .user-help {
  position: absolute !important;
  bottom: 36px !important;
  width: 100%;
  padding-left: 28px !important;
  color: var(--default-grey);
}

.sider .ant-menu-item-selected {
  background: var(--layout-menu-selected-background-color) !important;
  border-left: 5px solid rgb(106, 116, 133);
}

.sider .ant-menu-item-selected svg {
  margin-left: -8px;
  font-size: 1.2rem;
}

.header {
  width: 100%;
  height: 10vh;
  background: var(--layout-header-footer-background-color) !important;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 50px 0px 12px !important;
}

.header .anticon-menu-unfold,
.anticon-menu-fold {
  font-size: 24px;
  cursor: pointer;
}

.content {
  overflow: hidden;
  position: relative !important;
  width: 100%;
  height: 100%;
  background: var(--layout-content-background-color);
}

.controll .ant-tabs-top > .ant-tabs-nav::before {
  border-bottom: 0px !important;
}

.ant-tabs-tab-active svg {
  color: white !important;
}

.upload-checkbox .ant-checkbox-checked .ant-checkbox-inner {
  background-color: var(--default-grey) !important;
  border-color: var(--default-grey) !important;
}

.upload-checkbox .ant-checkbox:hover::after,
.ant-checkbox-wrapper:hover .ant-checkbox::after {
  border: 0 !important;
}

.upload-checkbox .ant-checkbox .ant-checkbox-inner {
  background-color: var(--default-grey) !important;
  border-color: var(--default-grey) !important;
}

.ant-checkbox-wrapper::after {
  display: none !important;
}

.controll .ant-tabs-ink-bar {
  position: absolute;
  background: white !important;
  pointer-events: none;
}

.footer {
  width: 100%;
  height: var(--layout-footer-height);
  display: flex;
  justify-content: center;
  align-items: center;
  background: var(--layout-header-footer-background-color) !important;
}

.footer .ant-menu-item-selected {
  background: var(--layout-menu-selected-background-color) !important;
  border-bottom: 5px solid rgb(106, 116, 133) !important;
}

.logo {
  height: 32px;
  margin: 16px;
  animation: logo-fadeIn 0.5s linear;
}
.icon {
  height: 32px;
  margin: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  animation: icon-fadeIn 0.5s linear;
}

.logo-icon {
  display: none;
}

.ant-layout-sider-trigger {
  /* background: var(--layout-trigger-background-color) !important; */
  height: 8vh !important;
}

.ant-typography-edit,
.ant-typography-edit-content-confirm {
  display: none !important;
}
.ant-descriptions-item-content textarea {
  margin-left: 12px !important;
}

.reports {
  height: 100vh;
  overflow-y: scroll;
}

@keyframes logo-fadeIn {
  from {
    opacity: 0%;
  }
  to {
    opacity: 100%;
  }
}

@keyframes icon-fadeIn {
  from {
    opacity: 0%;
  }
  to {
    opacity: 100%;
  }
}

@media screen and (max-width: 640px) {
  .footer-text,
  .menu-control,
  .sider {
    display: none !important;
  }
  .content {
    overflow-y: scroll;
  }
  .mobile-menu {
    display: block !important;
    width: 100%;
  }
  .footer {
    padding: 12px !important;
    height: auto;
  }
  .profile-card .ant-card-body {
    height: 100% !important;
  }
  .profile {
    grid-template-columns: 100% !important;
    grid-template-rows: 2 !important;
  }
  .expand-controls {
    top: 73vh !important;
    transform: rotate(90deg);
  }
  .logo-icon {
    display: block;
    block-size: 32px;
  }
}
